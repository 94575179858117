import styled from '@emotion/styled';
import { RawHtml } from '~/shared/components/RawHtml/RawHtml';
import { Headline } from '~/templates/blocks/components/Headline/Headline';
import { breakpoints } from '~/theme';

export const StyledHeader = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spaces[5],
    alignItems: 'end',
    width: '100%',
    margin: '0 auto',
    paddingBottom: theme.spaces[7],
    [breakpoints.sm]: {
        gridTemplateColumns: '2fr 1fr',
    },
}));

export const StyledHeaderContent = styled.div(({ theme }) => ({
    paddingRight: theme.spaces[7],
}));

export const StyledHeaderAction = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    a: {
        width: '100%',
    },
    [breakpoints.sm]: {
        width: 'auto',
        a: {
            width: 'auto',
        },
    },
});

export const StyledHeaderHeadline = styled(Headline)<{ description?: string }>(
    ({ theme, description }) => ({
        paddingBottom: description ? theme.spaces[5] : 0,
    })
);

export const StyledHeaderRawHtml = styled(RawHtml)(({ theme }) => ({
    ...theme.fontVariants.body,
    lineHeight: '3.5rem',
    'p:last-child': {
        margin: 0,
    },
}));
